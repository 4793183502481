import React from "react";
// import { Link } from "gatsby";

class Card extends React.Component {
  render() {
    return (
      <React.Fragment>
        { /* Square 3: .card--square-3. Uses img. */ }
        <div className="wp-block-columns has-1-columns card card--square-3">
          <div className="wp-block-column">
            <a href="/" className="wp-block-polb-card">
              <div className="card__photo-txt">
                <img src="https://via.placeholder.com/489x463/222/fff" alt="" />
                <p className="card__title">Air card</p>
              </div>
              <p className="card__blurb">Reducing harmful air emissions</p>
              <p className="view-more">View More</p>
            </a>

            <a href="/" className="wp-block-polb-card">
              <div className="card__photo-txt">
                <img src="https://via.placeholder.com/489x463/222/fff" alt="this has alt text" />
                <p className="card__title">Air</p>
              </div>
              <p className="card__blurb">Reducing harmful air emissions from activities at the port</p>
              <p className="view-more">View More</p>
            </a>

            <a href="/" className="wp-block-polb-card">
              <div className="card__photo-txt">
                <img src="https://via.placeholder.com/489x463/222/fff" alt="" />
                <p className="card__title">Air</p>
              </div>
              <p className="card__blurb">Reducing harmful air emissions from activities at the port</p>
              <p className="view-more">View More</p>
            </a>

            <a href="/" className="wp-block-polb-card">
              <div className="card__photo-txt">
                <img src="https://via.placeholder.com/489x463/222/fff" alt="" />
                <p className="card__title">Air</p>
              </div>
              <p className="card__blurb">Reducing harmful air emissions from activities at the port</p>
              <p className="view-more">View More</p>
            </a>

            <a href="/" className="wp-block-polb-card">
              <div className="card__photo-txt">
                <img src="https://via.placeholder.com/489x463/222/fff" alt="" />
                <p className="card__title">Air</p>
              </div>
              <p className="card__blurb">Reducing harmful air emissions from activities at the port</p>
              <p className="view-more">View More</p>
            </a>

            <a href="/" className="wp-block-polb-card">
              <div className="card__photo-txt">
                <img src="https://via.placeholder.com/489x463/222/fff" alt="" />
                <p className="card__title">Environment Sustainability</p>
              </div>
              <p className="card__blurb">Reducing harmful air emissions from activities at the port</p>
              <p className="view-more">View More</p>
            </a>
          </div>           
        </div>

        <hr />

        { /* Rectangular 4: Uses bg img. */ }
        { /* 4/18: learned from Jason that Community Grants Advisory Committee will NOT use this module after all. But the Commissioners page will use a similar one: https://projects.invisionapp.com/share/9HQYQQ884VW#/screens/352530957
        */ }
        <h2>Component: Card (max 4 rectangular cols)</h2>
        <p>This was slated to be used on Community Grants Program until POLB not to. [<a href="https://projects.invisionapp.com/share/9HQYQQ884VW#/screens/353728878" rel="noopener noreferrer" target="_blank"><strong>Design</strong></a>]</p>
        <p>A similar version will be used on the Commissioners page.</p>

        <div className="wp-block-columns has-1-columns card card--rect-4">
          <div className="card__photo-txt">
            <img src="https://via.placeholder.com/483x589/222/fff" alt="" />
            <p className="card__title">Director of Operations</p>
            <p className="card__name">Susan Terwilliger</p>
          </div>
          <div className="card__photo-txt">
            <img src="https://via.placeholder.com/483x589/222/fff" alt="" />
            <p className="card__title">Manager</p>
            <p className="card__name">Susan Jones</p>
          </div>
          <div className="card__photo-txt">
            <img src="https://via.placeholder.com/483x589/222/fff" alt="" />
            <p className="card__title">Asst. Manager</p>
            <p className="card__name">Bob Williams</p>
          </div>
          <div className="card__photo-txt">
            <img src="https://via.placeholder.com/483x589/222/fff" alt="" />
            <p className="card__title">Director of Operations</p>
            <p className="card__name">Susan Terwilliger</p>
          </div>
          <div className="card__photo-txt">
            <img src="https://via.placeholder.com/483x589/222/fff" alt="" />
            <p className="card__title">Manager</p>
            <p className="card__name">Susan Jones</p>
          </div>
          <div className="card__photo-txt">
            <img src="https://via.placeholder.com/483x589/222/fff" alt="" />
            <p className="card__title">Director of Operations</p>
            <p className="card__name">Susan Terwilliger</p>
          </div>
          <div className="card__photo-txt">
            <img src="https://via.placeholder.com/483x589/222/fff" alt="" />
            <p className="card__title">Manager</p>
            <p className="card__name">Susan Jones</p>
          </div>
          <div className="card__photo-txt">
            <img src="https://via.placeholder.com/483x589/222/fff" alt="" />
            <p className="card__title">Manager</p>
            <p className="card__name">Susan Jones</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Card;